import { Branding } from '../../branding.interface';

export const ABNIC_BRANDING: Branding = {
  termsAndConditionsURL: 'https://albuhaira.com/terms-and-conditions',
  privacyStatementURL: 'https://albuhaira.com/privacy-policy',
  pageTitle: 'ABNIC Health Care | Powered by CarePay',
  support: 'url',
  supportURL: 'https://albuhaira.com/contact-medical-team',
  healthTab: false,
  providerTab: false,
};
